import { environment } from "../../environments/environment";

export const EndPoints = {
  baseUrl: environment.baseApiUrl,
  Category: {
    list: "admin/Category/GetCategoriesList",
    add: "admin/Category/AddCategory",
    edit:  "admin/Category/UpdateCategory",
    delete: "admin/Category/DeleteCategory",
    getById:  "admin/Category/GetCategoryDetails",
    eventCategories: "admin/Category/GetCategoriesForEvent",
    KnwoledgeCenterCategories: "admin/Category/GetCategoriesForKnowledgeCenter",
  },
  Event: {
    list: "admin/Event/SearchEvents",
    add: "admin/Event/CreateEvent",
    edit:  "admin/Event/UpdateEvent",
    cancel:  "admin/Event/CancelEvent",
    postpone:  "admin/Event/PostponeEvent",
    delete: "admin/Event/DeleteEvent",
    getById:  "admin/Event/GetEventDetails",
    getEventCategoryById:  "admin/Event/GetEventCategoryDetails",
    pendingRequests: "admin/Event/GetPendingRequestsToAttendEventList",
    sentRequests: "admin/Event/GetPendingInvitationsList",
    acceptedRequests: "admin/Event/GetAcceptedUsersListToAttendEvent",
    attendanceList: "admin/Event/GetAttendanceEventList",
    replyToInvitationRequest: "admin/Event/ReplyToRequestToAttendEvent",
    sendInvitations: "admin/Event/SendInvitation",
  },
  KnowledgeCenter: {
    searchDocuments: "admin/KnowledgeCenter/SearchDocuments",
    addDocument: "admin/KnowledgeCenter/AddDocument",
    addMultipleDocuments: "admin/KnowledgeCenter/AddMultipleDocuments",
    editDocument:  "admin/KnowledgeCenter/UpdateDocument",
    deleteDocument: "admin/KnowledgeCenter/DeleteDocument",
    getDocumentDetails:  "admin/KnowledgeCenter/GetDocumentById",
    sendNotificationToAll:"admin/KnowledgeCenter/SendKnowledgeCenterToAll"
  },
  Stats: {
    totalUsers: "admin/statistics/UsersCount",
    totalActiveUsers: "admin/statistics/ActiveUsersCount",
    totalInactiveUsers: "admin/statistics/InactiveUsersCount",
    totalPosts: "admin/statistics/PostsCount",
    totalEvents: "admin/statistics/EventsCount",
    totalChats: "admin/statistics/ChatConversationsCount",
    totalKnowledgeCenterDocuments: "admin/statistics/KnowledgeCenterDocumentsCount",
    attendedEvents: "admin/statistics/TopAttendedEvents",
    commentedPosts: "admin/statistics/TopCommentedPosts",
    likedPosts: "admin/statistics/TopLikedPosts",
    postOwners: "admin/statistics/TopPostOwners",
    sharedPosts: "admin/statistics/TopSharedPosts",
    likedKnowledgeCenterDocuments: "admin/statistics/TopLikedKnowledgeCenterDocuments",
    sharedKnowledgeCenterDocuments: "admin/statistics/TopSharedKnowledgeCenterDocuments",
  },
  Product: {
    list: "ProductManagment/SearchProducts",
    add: "ProductManagment/CreateProduct",
    listPurchases: "SuperAdmin/SearchPurchases",
    Reviews: "ProductManagment/GetProductReviews",
    activate: "ProductManagment/DeactivateProduct",
    edit: "ProductManagment/EditProduct",
    archive: "ProductManagment/ArchiveProduct",
    getById: "ProductManagment/GetProductDetails",
    getPendingProducts: "ProductManagment/GetAllPendingProducts",
    approveReject: "ProductManagment/UpdateProductStatus",
  },
  Chats: {
    Individual: "admin/chat/IndividualChatConversations",
    Members: "admin/chat/GroupChatMembers",
    Messages: "admin/chat/ChatMessages",
    archive: "admin/chat/ArchiveChatGroup",
    groups: "admin/chat/GroupChatConversations",
  },
  Posts: {
    list: "Posts/ViewPostsList",
    archive: "Posts/DeletePost",
    add: "Posts/CreatePostFromAdmin",
    getById: "Posts/ViewPostAdminDetails",
    update: "Posts/UpdatePostFromAdmin",
  },
  Notification: {
    list: "admin/Notification/Notifications",
    send: "admin/Notification/SendNotificationToAllUsers",
    sendToDepartment:"admin/Notification/SendNotificationToDepartment"
  },
  User: {
    list: "admin/User/GetUsersList",
    add: "admin/User/CreateAdminUser",
    edit: "admin/User/UpdateAdminUser",
    laws: "admin/User/GetUserBlockLawsList",
    activate: "admin/User/ActivateUser",
    Profile: "Account/GetUserProfile",
    autoComplete: "Account/AutoCompleteUsers",
    deactivate: "admin/User/DeActivateUser",
    editProfile: "Auth/EditProfile",
    archive: "admin/User/DeleteAdminUser",
    getById: "admin/User/GetAdminUserProfile",
    verifyPhone: "Auth/VerfiyPhone",
    verifyEmail: "Auth/VerfiyEmail",
  },
  BlockLaw: {
    list: "admin/User/GetUserBlockLawsList",
    add: "admin/User/CreateUserBlockLaw",
    edit: "admin/User/UpdateUserBlockLaw",
    delete: "admin/User/DeleteUserBlockLaw",
    getById: "admin/User/GetUserBlockLawDetails",
  },
  Lookups: {
    role: "admin/User/GetRolesList",
    city: "City/GellAll",
    file: "Documents/GetAttachmentById/",
    eventCert:"Documents/UploadEventCertificate",
    addNewRole:"admin/User/CreateRole",
    deleteRole:"admin/User/DeleteRole",
    updateRole:"admin/User/UpdateRole"
  },
  Colors: {
    list: "Color/GetAllColors",
    listPaginated: "Color/GetAllColorsList",
    add: "Color/AddColor",
    edit: "Color/EditColor",
    archive: "Color/ArchiveColor",
    getById: "Color/GetColorById",
  },
  Promos: {
    list: "PromoCode/GetPromoCodeList",
    add: "PromoCode/AddPromoCode",
    edit: "PromoCode/EditPromoCode",
    archive: "PromoCode/ArchivePromoCode",
    getById: "PromoCode/GetPromoCode",
  },
  Curencies: {
    list: "Currency/GetAllCurrencies",
    listPaginated: "Currency/GetAllCurrenciesList",
    add: "Currency/AddCurrency",
    edit: "Currency/EditCurrency",
    archive: "Currency/ArchiveCurrency",
    getById: "Currency/GetCurrencyById",
  },
  Rates: {
    list: "CurrencyRate/GetCurrencyRatesList",
    add: "CurrencyRate/AddCurrencyRate",
    edit: "CurrencyRate/EditCurrencyRate",
    archive: "CurrencyRate/ArchiveCurrencyRate",
    getById: "CurrencyRate/GetCurrencyRate",
  },
  Cities: {
    list: "City/GellAll",
    listPaginated: "City/GetAllCities",
    add: "City/CreateCity",
    edit: "City/",
    archive: "City/",
    getById: "City/GetCity",
  },
  Appsetting:{
    aboutUs:"AppSettings/GetAboutUsSettings",
    privacy:"AppSettings/GetPrivacySetting",
    termsOfCondition:"AppSettings/GetTermsOfCondition",
    update:"admin/AppSettings/Update",
    allDepts:"admin/AppSettings/AllDepartments",
    AddDept:"admin/AppSettings/AddNewDepartment",
    UpdateDept:"admin/AppSettings/UpdateDepartment",
    allFtpUrls:"admin/AppSettings/AllFtpUrls",
    updateFtp:"admin/AppSettings/UpdateFtpUrl",
  }
};
