<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a class="logo" href="#" (click)="navigateHome()" dir="ltr">
      <img src="./assets/images/vision.png" class="m-1" width="80px;"/>
      <img src="./assets/images/logo2.png"  class="m-1" width="80px;"/>
    </a>
  </div>

</div>

<div class="header-container">
  <nb-select [selected]="currentLang" (selectedChange)="changeLang($event)" status="primary">
    <nb-option *ngFor="let language of languages" [value]="language"> {{ language |translate }}</nb-option>
  </nb-select>
  <nb-actions size="small">
    <nb-action class="control-item" icon="bell-outline"></nb-action>
    <nb-action class="user-action" *nbIsGranted="['view', 'user']" >
      <nb-user [nbContextMenu]="userMenu"
               [onlyPicture]="userPictureOnly"
               [name]="user?.given_name"
               [picture]="image">
      </nb-user>
    </nb-action>
  </nb-actions>
</div>
