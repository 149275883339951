<h1 id="title" class="title">{{'Change password' | translate}}</h1>
<p class="sub-title">{{'Please set a new password' | translate}}</p>

<nb-alert *ngIf="showMessages.error && errors?.length && !submitted" outline="danger" role="alert">
  <p class="alert-title"><b>{{'Oh snap!' | translate}}</b></p>
  <ul class="alert-message-list">
    <li *ngFor="let error of errors" class="alert-message">{{ error | translate }}</li>
  </ul>
</nb-alert>

<nb-alert *ngIf="showMessages.success && messages?.length && !submitted" outline="success" role="alert">
  <ul class="alert-message-list">
    <li *ngFor="let message of messages" class="alert-message">{{ message | translate}}</li>
  </ul>
</nb-alert>

<form (ngSubmit)="resetPass()" #resetPassForm="ngForm" aria-labelledby="title">

  <div class="form-control-group">
    <label class="label" for="input-password">{{'New Password' | translate}}:</label>
    <input nbInput
           [(ngModel)]="user.password"
           #password="ngModel"
           type="password"
           id="input-password"
           name="password"
           class="first"
           placeholder="{{'New Password' | translate}}"
           autofocus
           fullWidth
           fieldSize="large"
           [status]="password.dirty ? (password.invalid  ? 'danger' : 'success') : 'basic'"
           [required]="getConfigValue('forms.validation.password.required')"
           [minlength]="getConfigValue('forms.validation.password.minLength')"
           [maxlength]="getConfigValue('forms.validation.password.maxLength')"
           [attr.aria-invalid]="password.invalid && password.touched ? true : null">
    <ng-container *ngIf="password.invalid && password.touched">
      <p class="caption status-danger" *ngIf="password.errors?.required">
        {{'Password is required' | translate}}!
      </p>
      <p class="caption status-danger" *ngIf="password.errors?.minlength || password.errors?.maxlength">
        {{'Password should contains' | translate}}
        {{'from' | translate}} {{getConfigValue('forms.validation.password.minLength')}}
        {{'to' | translate}} {{getConfigValue('forms.validation.password.maxLength')}}
        {{'characters'| translate}}
      </p>
    </ng-container>
  </div>

  <div class="form-group">
    <label class="label" for="input-re-password">{{'Confirm Password'| translate }}:</label>
    <input nbInput
           [(ngModel)]="user.confirmPassword"
           #rePass="ngModel"
           id="input-re-password"
           name="rePass"
           type="password"
           class="last"
           placeholder="{{'Confirm Password'| translate }}"
           fullWidth
           fieldSize="large"
           [status]="rePass.touched
               ? (rePass.invalid || password.value != rePass.value ? 'danger' : 'success')
               : 'basic'"
           [required]="getConfigValue('forms.validation.password.required')"
           [attr.aria-invalid]="rePass.invalid && rePass.touched ? true : null">
    <ng-container *ngIf="rePass.touched">
      <p class="caption status-danger" *ngIf="rePass.invalid && rePass.errors?.required">
        {{'Password confirmation is required'| translate}}!
      </p>
      <p class="caption status-danger" *ngIf="password.value != rePass.value && !rePass.errors?.required">
        {{'Password does not match the confirm password.' | translate}}
      </p>
    </ng-container>
  </div>

  <button nbButton
          status="primary"
          fullWidth
          size="large"
          [disabled]="submitted || !resetPassForm.valid"
          [class.btn-pulse]="submitted">
    {{'Change password' | translate}}
  </button>
</form>